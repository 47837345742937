/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

declare var angular: angular.IAngularStatic;

import * as bowser from 'bowser';
import { DynamicInvoices } from './invoices-dynamicItems';

//// CONTROLLER ////
export class InvoicesController {
    // Private
    private paymentUrl: string;
    
    // Bindable
    public sessionData: any;
    public dynamicItems: any;
    public sortObj: any;
    public bowser: any;

    static get $inject() {
        return [
            '$state',
            '$scope',
            'loginService',
            'loggerService',
            'InvoiceResourceFactory',
            '$stateParams',
            '$timeout',
            '$window',
            'EndPointService'
        ];
    }

    constructor(
        public $state: any,
        public $scope: any,
        public loginService: any,
        public loggerService: any,
        public InvoiceResourceFactory: any,
        public $stateParams: any,
        public $timeout: any,
        public $window: any,
        public EndPointService: any,
    ) {
        /* PRIVATE : DATA */
        this.paymentUrl = 'https://www.comcasttechnologysolutions.com/user/login';

        /* BINDABLE : DATA */
        this.sessionData = this.loginService.getSessionData();
        this.dynamicItems = new DynamicInvoices(
            this.InvoiceResourceFactory,
            this.$stateParams,
            this.$timeout,
            this.loginService
        );
        this.sortObj = {
            field: this.$stateParams.sortField ? this.$stateParams.sortField : 'date_created',
            direction: this.$stateParams.sortDirection ? this.$stateParams.sortDirection : 'ASC',
        };
        this.bowser = bowser;

        /* EVENTS */
        var cancelSizing = $scope.$watch(
            () => {
                var testHeader = document.getElementsByClassName('sizable-header')[0];

                if (
                    testHeader &&
                    angular.element(testHeader) &&
                    angular.element(testHeader)[0]
                ) {
                    return angular.element(testHeader)[0].clientWidth;
                } else {
                    return 0;
                }
            },
            (oldVal: any, newVal: any) => {
                this.$timeout( () => {
                    // Set the flex-basis on all resizable headers
                    var headers = document.getElementsByClassName('sizable-header');

                    for (let i = 0; i < headers.length; i++) {
                        if (!bowser.msie) {
                            angular
                                .element(headers[i])
                                .css('flex-basis', angular.element(headers[i])[0].clientWidth);
                        } else {
                            angular
                                .element(headers[i])
                                .css('width', angular.element(headers[i])[0].clientWidth);
                        }
                    }
                });
            },
            true
        );

        $scope.$on('angular-resizable.resizing', (event: any, args: any) => {
            // Set the flex-basis on all resizable headers
            var headers = document.getElementsByClassName('sizable-header');

            for (let i = 0; i < headers.length; i++) {
                if (!bowser.msie) {
                    angular
                        .element(headers[i])
                        .css('flex-basis', angular.element(headers[i])[0].clientWidth);
                } else {
                    angular
                        .element(headers[i])
                        .css('width', angular.element(headers[i])[0].clientWidth);
                }
            }

            if (cancelSizing) {
                cancelSizing();
                cancelSizing = null;
            }
        });
    }

    /* IMPLEMENTATION ; BINDABLE */
    $onInIit() {
        // Initialize the controller
    }
    invoiceClick(invoice:any) {
        let vm = this;
        const accountName = vm.loginService.getSessionData().accountName;
        const accountId = vm.loginService.getSessionData().accountId;
        const name = invoice.name + ' Invoice Clicked for provider : ' + accountName;
        vm.loggerService.info(name, {invoiceName: invoice.name, invoiceAssetId: invoice.assetId, providerAccountName: accountName, providerAccountId: accountId});
    }
    downloadInvoice(assetId: string, $event: any) {
        let vm = this;
        const accountName = vm.loginService.getSessionData().accountName;
        let endpoint = vm.EndPointService.invoiceDownloadEndPoint;
            // Retrieve the
            vm.$window.open(
                endpoint +
                    '?' +
                    'assetId=' +
                    assetId +
                    '&' +
                    'authorization=' +
                    vm.loginService.getJwt() +
                    '&' +
                    'providerAccount=' +
                    accountName
            );
    }

    sortInvoices(field: any) {
        let vm = this;

        if (vm.sortObj.field === field) {
            vm.sortObj.direction = vm.sortObj.direction === 'ASC' ? 'DESC' : 'ASC';
        } else {
            vm.sortObj.field = field;
            vm.sortObj.direction = 'ASC';
        }
        vm.$state.go(
            'invoices',
            {
                sortField: vm.sortObj.field,
                sortDirection: vm.sortObj.direction,
            },
            { inherit: true }
        );
    }

    openPaymentPortal() {
        let vm = this;
        const accountName = vm.loginService.getSessionData().accountName;
        const accountId = vm.loginService.getSessionData().accountId;
        vm.loggerService.info('Pay Invoice Online Button Clicked', {providerAccountName: accountName, providerAccountId: accountId});
        vm.$window.open(this.paymentUrl, '_blank');
    }
}
