import { LoginService } from "../../common/authentication/authentication-service";
import * as bowser from 'bowser';

export class DynamicInvoices {
    // Private
    private SearchParams: any;
    private PAGE_SIZE: number;
    private loadedPages: any;

    // Bindable
    public sessionData: any;
    public sortObj: any;
    public bowser: any;
    public numItems: number;
    public searching: Boolean = true;

    static get $inject() {
        return [
            'InvoiceResourceFactory',
            '$stateParams',
            '$timeout'
        ];
    }

    constructor (
        public InvoiceResourceFactory: any,
        public $stateParams: any,
        public $timeout: any,
        public loginService: LoginService
    ) {
        /* PRIVATE : DATA */
        this.SearchParams = $stateParams;
        this.PAGE_SIZE = 50;
        this.numItems = 0;

        /* BINDABLE : DATA */
        this.sessionData = this.loginService.getSessionData();
        this.sortObj = {
            field: $stateParams.sortField ? $stateParams.sortField : 'date_created',
            direction: $stateParams.sortDirection ? $stateParams.sortDirection : 'ASC',
        };
        this.bowser = bowser;

        /* Get things rolling */
        this.reloadPages_();
    }

    getItemAtIndex = (index: any, lazy?: any) => {
        var pageNumber = Math.floor(index / this.PAGE_SIZE);
            var page = this.loadedPages[pageNumber];
            lazy = lazy === undefined ? false : lazy;
    
            if (page) {
                return page[index % this.PAGE_SIZE];
            } else if (page !== null) {
                if (!lazy) {
                    this.fetchPage_(pageNumber);
                }
            }
    }

    getLength = () => {
        return this.numItems;
    }

    setItemAtIndex = (index: number, item: any) => {
        var pageNumber = Math.floor(index / this.PAGE_SIZE);
            var page = this.loadedPages[pageNumber];
    
            // Only update the screen if the spot is currently loaded
            if (page) {
                page[index % this.PAGE_SIZE] = item;
            }
    }

    fetchPage_ = (pageNumber: any) => {
        this.searching = true;
    
            // Set the page to null so we know it is already being fetched.
            this.loadedPages[pageNumber] = null;
    
            var pageOffset = pageNumber * this.PAGE_SIZE;
    
            var sortField = this.$stateParams.sortField ? this.$stateParams.sortField : 'date_created';
            var sortDirection = this.$stateParams.sortDirection
                ? this.$stateParams.sortDirection
                : 'DESC';
    
            this.$timeout( () => {
                // Filter out blank values, since we don't want to search by those
                for (var prop in this.SearchParams) {
                    if (this.SearchParams.hasOwnProperty(prop)) {
                        this.SearchParams[prop] =
                            this.SearchParams[prop] === '' || this.SearchParams[prop] === null
                                ? undefined
                                : this.SearchParams[prop];
                    }
                    if (this.SearchParams[prop] === undefined) delete this.SearchParams[prop];
                }
    
                this.SearchParams.offset = pageOffset;
                this.SearchParams.limit = this.PAGE_SIZE;
                this.SearchParams.sortField = sortField;
                this.SearchParams.sortDirection = sortDirection;
                // TODO: Append all of these values to the params object from $stateParams so we can send one big object
                this.InvoiceResourceFactory.getAll(this.SearchParams).subscribe(
                    (invoices: any) => {
                        this.loadedPages[pageNumber] = invoices.rows;
                        this.numItems = invoices.count;
                        this.searching = false;
                    },
                    (err: any) => {
                        console.log(err);
                    }
                );
            });
    }

    reloadPages_ = () => {
        /**
         * @type {!Object<?Array>} Data pages, keyed by page number (0-index).
         */
        this.loadedPages = {};
    
        /** @type {number} Total number of items. */
        this.numItems = 0;
 
        /** @const {number} Number of items to fetch per request. */
        this.PAGE_SIZE = 50;
         
        this.getItemAtIndex(0);
    }
}
    